export enum EventTemplateAssetType {
  BACKGROUND = 'BACKGROUND',
  EFFECT = 'EFFECT',
  KEY_VISUAL = 'KEY_VISUAL',
}

export namespace EventTemplateAssetType {
  export function getAllEventTemplateTypes(): EventTemplateAssetType[] {
    return [
      EventTemplateAssetType.BACKGROUND,
      EventTemplateAssetType.EFFECT,
      EventTemplateAssetType.KEY_VISUAL,
    ];
  }

  export function getDisplayName(type?: EventTemplateAssetType): string {
    switch (type) {
      case EventTemplateAssetType.BACKGROUND:
        return 'APP.CHANGE_EFFECTS.BACKGROUND';
      case EventTemplateAssetType.EFFECT:
        return 'APP.CHANGE_EFFECTS.OVERLAY';
      case EventTemplateAssetType.KEY_VISUAL:
        return 'APP.CHANGE_EFFECTS.KEY_VISUAL';
      default:
        return '';
    }
  }
}
